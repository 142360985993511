export const COLORS = {
  EBONY: '#05050D',
  EBONY_70: '#05050DB2',
  EBONY_50: '#05050d80',
  GREEN: '#33FF71',
  STORM_GRAY: '#74767B',
  GRAY_OPACITY: '#8E8E8EB2',
  WHITE: '#FFFFFF',
  WHITE_70: '#FFFFFFB3',
  LIGHT: '#F1F6F6',
  TRANSPARENT: 'transparent',
  RED: '#F23333',
  MALIBU: '#75F3FB',
  MALIBU_LIGHT: '#75F3FBB2',
  MALIBU_NIGHT: '#3498B7',
  PURPLE: '#A498FF',
  PURPLE_LIGHT: '#A498FFB2',
  CHARTREUSE_YELLOW: '#DFF800',
  CHARTREUSE_YELLOW_LIGHT: '#DFF80080',
  TITAN_WHITE: '#ECEAFF',
  ZUMTHOR: '#EEF7FF',
  SCANDAL: '#DDF9FB',
  MIMOSA: '#F9FDD6',
  AZURE_RADIANCE: '#1877F2',
  LINK: '#0074E0',
  SQUEEZE: '#E1EFF3',
  GREEN_LIKE: '#1BD16F',
  DISLIKE: '#D74646',
  LIGHT_GRAY: '#B3C0C2',
};
