import { useEffect, useRef, useState } from 'react';

let isFirstMessage: boolean =
  typeof window !== 'undefined' &&
  JSON.parse(localStorage.getItem('quidget-init-message') || 'true');

export const useConversationTracking = () => {
  const observerRef = useRef<MutationObserver>();
  const [isQuidgetInit, setIsQuidgetInit] = useState(false);
  const [quidgetNodeState, setQuidgetNodeState] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const quidgetNode = document.getElementById('quidget');

    if (quidgetNode) {
      setQuidgetNodeState(quidgetNode);
    }
  }, [isQuidgetInit]);

  const dataLayerHandler = () => {
    const isRefreshedQuidget =
      typeof window !== 'undefined' && window.history.state?.isRefreshedQuidget;

    if (isFirstMessage || isRefreshedQuidget) {
      localStorage.setItem('quidget-init-message', 'false');
      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'submit_form_ai',
          status: 'successful',
          tag: 'start_chat_with_ai',
        });
      }
      isFirstMessage = false;
      window.history.pushState({ isRefreshedQuidget: false }, '', '');
    }
  };

  const keyDownHandler = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      dataLayerHandler();
    }
  };
  const clickHandler = () => {
    dataLayerHandler();
  };

  useEffect(() => {
    if (quidgetNodeState && quidgetNodeState?.children?.length) {
      const quidgetNodeTextArea = quidgetNodeState?.getElementsByTagName('textarea')[0];
      const quidgetSendButton = quidgetNodeState?.getElementsByClassName('q-form-button-send')[0];
      quidgetNodeTextArea.addEventListener('keydown', keyDownHandler);
      quidgetSendButton.addEventListener('click', clickHandler);
      return () => {
        quidgetNodeTextArea.removeEventListener('keydown', keyDownHandler);
        quidgetSendButton.removeEventListener('click', clickHandler);
      };
    }
  }, [quidgetNodeState, observerRef.current]);

  useEffect(() => {
    const handleMutations: MutationCallback = (mutationsList) => {
      setIsQuidgetInit(false);
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          const quidgetElement = document.getElementById('quidget');
          if (!!quidgetElement?.clientHeight && quidgetElement?.children?.length) {
            setIsQuidgetInit(true);
            return;
          }
        }
      }
    };

    const observer = new MutationObserver(handleMutations);

    observer.observe(document.body, { childList: true, subtree: true });
    observerRef.current = observer;

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);
};
